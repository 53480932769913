import { Link } from 'react-router-dom';
import { formatNameForURL } from '@/utils/formatNameForURL';
import { AWS_BASE_URL_ASSETS } from '@/config/seviceApiConfig';
import { motion } from 'framer-motion';

const menuVariants = {
  hidden: { opacity: 0, y: -10 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const itemVariants = {
  hidden: { opacity: 0, y: -10 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.25 } },
};

const HeroMegaMenu = ({ data }) => {
  return (
    <section className="absolute top-[45px] backdrop-blur-lg transform lg:left-[50%] lg:-translate-x-[41.5%] 2xl:left-[50%] 2xl:-translate-x-[41%] 4xl:left-[50%] 4xl:-translate-x-[46.5%]">
      <motion.div
        className="main-hero-container flex items-center justify-between bg-white bg-opacity-[70%] rounded-md shadow py-10 font-normal text-[13px] text-blue-580"
        initial="hidden"
        animate="visible"
        variants={menuVariants}
      >
        <div className="w-[72%]">
          <ul className="grid grid-cols-4 gap-y-5">
            {data?.map((state) => (
              <motion.li
                key={state.state_id}
                className=""
                variants={itemVariants}
              >
                <span className="font-bold text-base">
                  <Link
                    to={`permanent-jobs/${formatNameForURL(state.name)}`}
                    state={{ stateID: state.state_id }}
                  >
                    {state.name}
                  </Link>
                </span>
                <ul className="mt-2">
                  {state?.regions.map((region) => (
                    <motion.li
                      key={region.regions_id}
                      className="p-1 transition duration-300 text-sm ease-in-out hover:text-blue-500 hover:font-bold"
                      variants={itemVariants}
                    >
                      <Link
                        to={`/region/permanent-jobs/${formatNameForURL(
                          region.name
                        )}`}
                      >
                        {region.name}
                      </Link>
                    </motion.li>
                  ))}
                </ul>
              </motion.li>
            ))}
          </ul>
        </div>
        <div className="w-[23%]">
          <motion.div variants={itemVariants}>
            <Link to="/premier-locum-general-practitioner">
              <img
                src={`${AWS_BASE_URL_ASSETS}/premierlocumgp.png`}
                alt="general-practitioner"
                className="mb-5 shadow-lg"
              />
            </Link>
          </motion.div>
          <motion.div variants={itemVariants}>
            <Link to="/premier-locum-doctor">
              <img
                src={`${AWS_BASE_URL_ASSETS}/premierlocumdoctor.png`}
                alt="premier-doctor"
                className="my-5 shadow-lg"
              />
            </Link>
          </motion.div>
          <motion.div variants={itemVariants}>
            <Link to="/allied-health-professional">
              <img
                src={`${AWS_BASE_URL_ASSETS}/spotworkforce.png`}
                alt="spot-workforce"
                className="mb-5 shadow-lg"
              />
            </Link>
          </motion.div>
          <motion.div variants={itemVariants}>
            <Link to="/nursing">
              <img
                src={`${AWS_BASE_URL_ASSETS}/nurse.png`}
                alt="nurse"
                className="shadow-lg"
              />
            </Link>
          </motion.div>
        </div>
      </motion.div>
    </section>
  );
};

export default HeroMegaMenu;
