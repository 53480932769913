import { useLocation, matchPath } from 'react-router-dom';
import AppRouter from '@/router/AppRouter';
import { MobileFooter } from '@/components';
import useResponsive from '@/hooks/useResponsive';
import {
  DynamicHeaderLayout,
  FooterLayout,
  MobileHeaderLayout,
} from '@/layout';
import HeaderLayout from '@/layout/desktop/HeaderLayout';
import { useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import useFetchStatesWithRegions from '@/hooks/useFetchStatewithRegions';
import useFetchBottomMenuData from '@/components/navbar/hook/useBottomNavBar';
import SocialMediaWidget from '@/components/socialMediaWidget/SocialMediaWidget';

function isPermanentOrLocumJobsRoute(pathname: string) {
  return (
    (pathname.startsWith('/permanent-') || pathname.startsWith('/locum-')) &&
    pathname.includes('-jobs-in-')
  );
}

function App() {
  const { isMobile } = useResponsive();
  const location = useLocation();
  const { stateWithRegions, fetchStatesWithRegions } =
    useFetchStatesWithRegions();
  const { bottomMenuData } = useFetchBottomMenuData();

  const routes = [
    '/permanent-jobs/:location/:profession-jobs',
    '/locum-jobs/:location/:profession-jobs',
    // '/permanent-jobs/:stateName',
    // '/locum-jobs/:stateName',
    '/specialty/:specialtyUrl',
    '/region/permanent-jobs/:regionName',
    '/region/locum-jobs/:regionName',
    '/:stateName/:regionName/jobs',
    '/all-doctor-jobs-in-australia',
    '/all-ahp-jobs-in-australia',
    '/specialty/permanent/:specialty-jobs-in-australia',
    '/specialty/locum/:specialty-jobs-in-australia',
    '/seniority/permanent/:seniority-jobs-in-australia',
    '/seniority/locum/:seniority-jobs-in-australia',
    '/city/all-jobs/:cityName',
  ];

  const isAllJobsRoute = routes.some((pattern) =>
    matchPath(pattern, location.pathname)
  );

  const scrollRoutes = [
    '/',
    '/terms-and-conditions',
    '/privacy-policy',
    '/compliance',
    '/contact-us',
    '/register',
    '/sign-in',
    '/premier-locum-general-practitioner',
    '/allied-health-professional',
    '/premier-locum-doctor',
    '/permanent-recruitment',
    '/temporary-recruitment',
    '/international-recruitment',
    '/nursing',
    '/many-requests',
    '/customer-feedback',
    '/consultation',
    '/short-consultation',
    '/full-consultation',
    '/career-consultation',
    '/all-jobs-in-australia',
  ];
  useEffect(() => {
    if (scrollRoutes.includes(location.pathname)) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  useEffect(() => {
    const currentPath = window.location.pathname;

    if (currentPath === '/allied-health-professional') {
      document.documentElement.style.setProperty(
        '--scrollbar-color',
        'linear-gradient(84.66deg, #1d8498 64.88%, #2db1ca 87.57%)'
      );
    } else if (currentPath === '/nursing') {
      document.documentElement.style.setProperty(
        '--scrollbar-color',
        'linear-gradient(108.95deg, #6F0F4C 58.96%, #C01A84 115.63%)'
      );
    } else {
      document.documentElement.style.setProperty(
        '--scrollbar-color',
        '#07121E'
      );
    }
  }, [location.pathname]);

  return (
    <>
      <SocialMediaWidget />
      <AnimatePresence mode="wait">
        {isMobile ? (
          <>
            <MobileHeaderLayout />
            <AppRouter bottomMenuData={bottomMenuData} />
            <MobileFooter />
          </>
        ) : isPermanentOrLocumJobsRoute(location.pathname) || isAllJobsRoute ? (
          <>
            <DynamicHeaderLayout
              stateWithRegions={stateWithRegions}
              fetchStatesWithRegions={fetchStatesWithRegions}
              bottomMenuData={bottomMenuData}
            />
            <AppRouter bottomMenuData={bottomMenuData} />
            <FooterLayout />
          </>
        ) : (
          <>
            <HeaderLayout
              stateWithRegions={stateWithRegions}
              fetchStatesWithRegions={fetchStatesWithRegions}
              bottomMenuData={bottomMenuData}
            />
            <AppRouter bottomMenuData={bottomMenuData} />
            <FooterLayout />
          </>
        )}
      </AnimatePresence>
    </>
  );
}

export default App;
