import { matchPath, useLocation } from 'react-router-dom';
import { BottomNavbar, CommonTopNavbar, TopNavbar } from '@/components';

export default function HeaderLayout({
  stateWithRegions,
  fetchStatesWithRegions,
  bottomMenuData,
}) {
  const location = useLocation();

  const renderTopNavbar = () => {
    const path = location.pathname;
    if (
      path === '/' ||
      path === '/premier-locum-general-practitioner' ||
      path === '/premier-locum-doctor' ||
      path === '/allied-health-professional' ||
      path === '/nursing' ||
      matchPath('/permanent-jobs/:stateName', path) ||
      matchPath('/locum-jobs/:stateName', path)
    ) {
      return (
        <TopNavbar
          stateWithRegions={stateWithRegions}
          fetchStatesWithRegions={fetchStatesWithRegions}
        />
      );
    } else if (
      matchPath('/register', path) ||
      matchPath('/all-jobs-in-australia', path) ||
      matchPath('/jobs-in-:location-state/au', path) ||
      matchPath('/:profession-jobs-in-australia/au', path) ||
      matchPath('/:location-state-jobs-in-australia/:id', path) ||
      matchPath('/apply-now/locum/:title/:jobId', path) ||
      matchPath('/apply-now/permanent/:title/:jobId', path) ||
      matchPath('/employer-register', path) ||
      matchPath('/contact-us', path) ||
      matchPath('/terms-and-conditions', path) ||
      matchPath('/privacy-policy', path) ||
      matchPath('/compliance', path) ||
      matchPath('/sign-in', path) ||
      matchPath('/client-sign-in', path) ||
      matchPath('/employer-service', path) ||
      matchPath('/permanent-recruitment', path) ||
      matchPath('/temporary-recruitment', path) ||
      matchPath('/international-recruitment', path) ||
      matchPath('/page-not-found', path) ||
      matchPath('/refer-and-earn', path) ||
      matchPath('/many-requests', path) ||
      matchPath('/maintenance', path) ||
      matchPath('/server-error', path) ||
      matchPath('/all-permanent-doctor-jobs-in-australia', path) ||
      matchPath('/all-locum-doctor-jobs-in-australia', path) ||
      matchPath(
        '/all-permanent-occupational-therapists-jobs-in-australia',
        path
      ) ||
      matchPath('/all-locum-occupational-therapists-jobs-in-australia', path) ||
      matchPath('/all-permanent-speech-pathologist-jobs-in-australia', path) ||
      matchPath('/all-locum-speech-pathologist-jobs-in-australia', path) ||
      matchPath('/all-permanent-speech-therapist-jobs-in-australia', path) ||
      matchPath('/all-locum-speech-therapist-jobs-in-australia', path) ||
      matchPath('/all-permanent-physiotherapist-jobs-in-australia', path) ||
      matchPath('/all-locum-physiotherapist-jobs-in-australia', path) ||
      matchPath('/all-permanent-dentists-jobs-in-australia', path) ||
      matchPath('/all-locum-dentists-jobs-in-australia', path) ||
      matchPath('/all-permanent-nurses-jobs-in-australia', path) ||
      matchPath('/all-locum-nurses-jobs-in-australia', path) ||
      matchPath('/all-permanent-psychology-jobs-in-australia', path) ||
      matchPath('/all-locum-psychology-jobs-in-australia', path) ||
      matchPath('/all-permanent-psychology-jobs-in-australia', path) ||
      matchPath('/candidate-consultation', path) ||
      matchPath('/employer-consultation', path) ||
      matchPath('/customer-feedback', path) ||
      matchPath('/candidate-short-consultation', path) ||
      matchPath('/candidate-full-consultation', path) ||
      matchPath('/candidate-career-consultation', path) ||
      matchPath('/employer-short-consultation', path) ||
      matchPath('/employer-full-consultation', path) ||
      matchPath('/employer-90-minutes-consultation', path) ||
      matchPath('/international-medical-graduate-recruitment', path)
    ) {
      return (
        <CommonTopNavbar
          stateWithRegions={stateWithRegions}
          fetchStatesWithRegions={fetchStatesWithRegions}
        />
      );
    }
  };

  const renderBottomNavbar = () => {
    const path = location.pathname;
    if (
      path === '/premier-locum-general-practitioner' ||
      path === '/premier-locum-doctor' ||
      path === '/allied-health-professional' ||
      path === '/nursing'
    ) {
      return null;
    } else {
      return <BottomNavbar bottomMenuData={bottomMenuData} />;
    }
  };

  const getBackgroundColor = () => {
    const path = location.pathname;
    if (
      path === '/' ||
      matchPath('/permanent-jobs/:stateName', path) ||
      matchPath('/locum-jobs/:stateName', path)
    ) {
      return 'bg-blue-500';
    } else if (
      matchPath('/register', path) ||
      matchPath('/all-jobs-in-australia', path) ||
      matchPath('/jobs-in-:location-state/au', path) ||
      matchPath('/:profession-jobs-in-australia/au', path) ||
      matchPath('/:location-state-jobs-in-australia/:id', path) ||
      matchPath('/apply-now/permanent/:title/:jobId', path) ||
      matchPath('/apply-now/locum/:title/:jobId', path) ||
      matchPath('/employer-register', path) ||
      matchPath('/contact-us', path) ||
      matchPath('/terms-and-conditions', path) ||
      matchPath('/privacy-policy', path) ||
      matchPath('/compliance', path) ||
      matchPath('/sign-in', path) ||
      matchPath('/client-sign-in', path) ||
      matchPath('/employer-service', path) ||
      matchPath('/permanent-recruitment', path) ||
      matchPath('/temporary-recruitment', path) ||
      matchPath('/international-recruitment', path) ||
      matchPath('/page-not-found', path) ||
      matchPath('/refer-and-earn', path) ||
      matchPath('/many-requests', path) ||
      matchPath('/maintenance', path) ||
      matchPath('/server-error', path) ||
      matchPath('/all-permanent-doctor-jobs-in-australia', path) ||
      matchPath('/all-locum-doctor-jobs-in-australia', path) ||
      matchPath(
        '/all-permanent-occupational-therapists-jobs-in-australia',
        path
      ) ||
      matchPath('/all-locum-occupational-therapists-jobs-in-australia', path) ||
      matchPath('/all-permanent-speech-pathologist-jobs-in-australia', path) ||
      matchPath('/all-locum-speech-pathologist-jobs-in-australia', path) ||
      matchPath('/all-permanent-speech-therapist-jobs-in-australia', path) ||
      matchPath('/all-locum-speech-therapist-jobs-in-australia', path) ||
      matchPath('/all-permanent-physiotherapist-jobs-in-australia', path) ||
      matchPath('/all-locum-physiotherapist-jobs-in-australia', path) ||
      matchPath('/all-permanent-dentists-jobs-in-australia', path) ||
      matchPath('/all-locum-dentists-jobs-in-australia', path) ||
      matchPath('/all-permanent-nurses-jobs-in-australia', path) ||
      matchPath('/all-locum-nurses-jobs-in-australia', path) ||
      matchPath('/all-permanent-psychology-jobs-in-australia', path) ||
      matchPath('/all-locum-psychology-jobs-in-australia', path) ||
      matchPath('/all-permanent-psychology-jobs-in-australia', path) ||
      matchPath('/customer-feedback', path) ||
      matchPath('/candidate-consultation', path) ||
      matchPath('/employer-consultation', path) ||
      matchPath('/candidate-short-consultation', path) ||
      matchPath('/candidate-full-consultation', path) ||
      matchPath('/candidate-career-consultation', path) ||
      matchPath('/employer-short-consultation', path) ||
      matchPath('/employer-full-consultation', path) ||
      matchPath('/employer-90-minutes-consultation', path) ||
      matchPath('/international-medical-graduate-recruitment', path)
    ) {
      return 'bg-white';
    } else if (
      path === '/premier-locum-general-practitioner' ||
      path === '/premier-locum-doctor'
    ) {
      return 'bg-gradient-premierHero';
    } else if (path === '/allied-health-professional') {
      return 'bg-gradient-spotHero';
    } else if (path === '/nursing') {
      return 'bg-gradient-nurse';
    }
  };

  const getBottomBackgroundColor = () => {
    const path = location.pathname;
    if (
      path === '/premier-locum-general-practitioner' ||
      path === '/premier-locum-doctor'
    ) {
      return 'bg-gradient-premierHero';
    } else if (path === '/allied-health-professional') {
      return 'bg-gradient-spotHero';
    } else {
      return 'bg-blue-500';
    }
  };

  return (
    <section className="sticky top-0 z-10">
      <nav className={`max-width-container  ${getBackgroundColor()}`}>
        <div className="main-width-container">{renderTopNavbar()}</div>
      </nav>
      <nav className={`max-width-container  ${getBottomBackgroundColor()}`}>
        <div className="main-width-container">{renderBottomNavbar()}</div>
      </nav>
    </section>
  );
}
