import { useEffect, useState, useMemo, useCallback } from 'react';
import GetAllJobDetailsApi from '@/api/GetAllJobDetailsApi';
import GetAllBrowserJobsApi from '@/api/GetAllBrowserJobsApi';

interface FilterJobsData {}

interface BrowserJobs {}

interface FetchJobFilterData {
  filterData: FilterJobsData[] | BrowserJobs[] | null;
  FilterDataLoading: boolean;
  FilterDataError: Error | null;
}

interface FilterJobsParameterProps {
  divisionId?: number;
  professionId?: number;
  stateId?: number;
  specialtyId?: number;
  seniorityId?: number;
  regionId?: number;
  suburbId?: number;
}

export default function useFetchFilterAllJobsDataDetails({
  divisionId,
  professionId,
  stateId,
  specialtyId,
  seniorityId,
  regionId,
  suburbId,
}: FilterJobsParameterProps): FetchJobFilterData {
  const [browserJobsData, setBrowserJobsData] = useState<BrowserJobs[] | null>(
    null
  );
  const [filterData, setFilterData] = useState<FilterJobsData[] | null>(null);
  const [FilterDataLoading, setLoading] = useState<boolean>(true);
  const [FilterDataError, setError] = useState<Error | null>(null);

  // Memoized parameters
  const memoizedParams = useMemo(
    () => ({
      divisionId,
      professionId,
      stateId,
      specialtyId,
      seniorityId,
      regionId,
      suburbId,
    }),
    [
      divisionId,
      professionId,
      stateId,
      specialtyId,
      seniorityId,
      regionId,
      suburbId,
    ]
  );

  // Check if at least one parameter is defined
  const hasAtLeastOneParam = useMemo(
    () =>
      divisionId !== undefined ||
      professionId !== undefined ||
      stateId !== undefined ||
      specialtyId !== undefined ||
      seniorityId !== undefined ||
      regionId !== undefined ||
      suburbId !== undefined,
    [
      divisionId,
      professionId,
      stateId,
      specialtyId,
      seniorityId,
      regionId,
      suburbId,
    ]
  );

  // Check if at least two parameters are defined
  const hasAtLeastTwoParams = useMemo(() => {
    const params = [
      divisionId,
      professionId,
      stateId,
      specialtyId,
      seniorityId,
      regionId,
      suburbId,
    ];
    return params.filter((param) => param !== undefined).length >= 2;
  }, [
    divisionId,
    professionId,
    stateId,
    specialtyId,
    seniorityId,
    regionId,
    suburbId,
  ]);

  // Check if three specific parameters are defined (e.g., divisionId, professionId, stateId)
  const hasAllRequiredParams = useMemo(
    () =>
      divisionId !== undefined &&
      professionId !== undefined &&
      stateId !== undefined,
    [divisionId, professionId, stateId]
  );

  // Memoized fetch function
  const fetchData = useCallback(async () => {
    setLoading(true);

    if (hasAllRequiredParams || hasAtLeastTwoParams || hasAtLeastOneParam) {
      try {
        const response = await GetAllJobDetailsApi(memoizedParams);
        setFilterData(response.data);
        setError(null);
      } catch (error) {
        setError(error as Error);
        setFilterData(null);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const response = await GetAllBrowserJobsApi();
        setBrowserJobsData(response);
        console.log(response);
        setError(null);
      } catch (error) {
        setError(error as Error);
        setBrowserJobsData(null);
      } finally {
        setLoading(false);
      }
    }
  }, [
    hasAllRequiredParams,
    hasAtLeastTwoParams,
    hasAtLeastOneParam,
    memoizedParams,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    filterData: filterData ?? browserJobsData,
    FilterDataLoading,
    FilterDataError,
  };
}
