import { useEffect, useState } from 'react';
import Button from '@/components/shared/button/Button';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { verifyEmailFormat } from '@/utils/EmailVerification';
import { isPhoneValid } from '@/utils/PhoneNumberVerification';
import { Link, useLocation } from 'react-router-dom';
import pdfIcon from '/icons/pdfIcon.png';
import wordIcon from '/icons/wordIcon.png';
import { ProfessionApply } from '../forms/selectors/ProfessionApply';
import { FORM_ERROR_TIME } from '@/constants/Constant';
import { getBaseUrl } from '@/config/BaseUrl';
import ApplyQuickApi from '@/api/Registration/QuickApplyApi';
import { AWS_BASE_ASSETS_ICONS } from '@/config/seviceApiConfig';
import { ProfessionSelector } from '../forms/selectors/ProfessionSelector';
import useResponsive from '@/hooks/useResponsive';
import PopupNotification from '../shared/notification/PopupNotification';
import SubmitButton from '../shared/button/SubmitBtn';

interface QuickApplyFormProps {
  isOpen: boolean;
  onClose: () => void;
  jobID: number;
  profession: string;
}

export default function LocumRegister({
  isOpen,
  onClose,
  jobID,
  profession,
}: QuickApplyFormProps) {
  const { isMobile } = useResponsive();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    profession: profession,
    phoneNumber: '',
    fileUpload: '',
    agree: false,
    subscribe: false,
  });
  const [file, setFile] = useState<File | null>(null);
  const [submit, setSubmit] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    // profession: '',
    phoneNumber: '',
    fileUpload: '',
    agree: false,
  });
  const [click, setClick] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [notification, setNotification] = useState({
    show: false,
    title: '',
    message: '',
    type: 'success' as 'success' | 'error',
  });
  const baseUrl = getBaseUrl();

  const handleChange = (e: {
    target: { id: string; type: string; checked: boolean; value: string };
  }) => {
    const { id, type, checked, value } = e.target;
    setFormData({
      ...formData,
      [id]: type === 'checkbox' ? checked : value,
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const validateForm = () => {
    const newErrors = {
      firstName: !formData.firstName ? 'Please enter your first name' : '',
      lastName: !formData.lastName ? 'Please enter your last name' : '',
      email: !formData.email
        ? 'Please enter your email address'
        : !verifyEmailFormat(formData.email)
          ? 'Please enter a valid email address'
          : '',
      // profession: !formData.profession ? 'Please select profession' : '',
      phoneNumber: !formData.phoneNumber
        ? 'Please enter your Phone Number'
        : !isPhoneValid(formData.phoneNumber)
          ? 'Please enter a valid Phone Number'
          : '',
      fileUpload: !file ? 'Please upload your cv here' : '',
      agree: !formData.agree
        ? 'You must agree to the Terms and Conditions and Privacy Policy'
        : '',
    };
    setFormErrors(newErrors);
    return newErrors;
  };

  useEffect(() => {
    if (click) {
      validateForm();
    }
  }, [click, formData]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setClick(true);
    const errors = validateForm();
    if (Object.values(errors).some((error) => error)) {
      const firstError = Object.keys(errors).find(
        (key) => errors[key as keyof typeof errors]
      );
      setIsSubmitting(false);
      return;
    }
    try {
      setIsSubmitting(true);
      const randomNumber = Math.floor(Math.random() * 1000000);
      const fileData = new FormData();
      fileData.append('image', file);
      fileData.append('folder_path', 'desktop/candidate_resume');
      fileData.append('file_name', `${formData.firstName}_${randomNumber}`);

      const fileUploadResponse = await fetch(`${baseUrl}upload`, {
        method: 'POST',
        body: fileData,
      });
      const fileUploadResult = await fileUploadResponse.json();

      if (!fileUploadResult) {
        throw new Error('File upload failed');
      } else {
        const apiResponse = await ApplyQuickApi(
          formData.firstName,
          formData.lastName,
          formData.phoneNumber,
          formData.email,
          formData.profession,
          fileUploadResult.fileName,
          jobID
        );
        if (apiResponse) {
          setSubmit(true);
          setClick(false);
          setFormData({
            firstName: '',
            lastName: '',
            email: '',
            profession: profession,
            phoneNumber: '',
            fileUpload: '',
            agree: false,
            subscribe: false,
          });
          setFile(null);
        }
      }
    } catch (error) {
      console.error('Error during registration: ', error);
      setNotification({
        show: true,
        title: 'Registration Error',
        message:
          'An error occurred during registration. Please try again later.',
        type: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderBtnColor = () => {
    return 'form-register-gold-quick-btn';
  };

  const getIconForFileType = (fileName: string) => {
    const extension = fileName.split('.').pop()?.toLowerCase();
    if (extension === 'pdf') {
      return <img src={pdfIcon} alt="PDF Icon" className="w-10 h-10 mr-2" />;
    } else if (['txt', 'doc', 'docx'].includes(extension || '')) {
      return <img src={wordIcon} alt="Text Icon" className="w-10 h-10 mr-2" />;
    } else {
      return null;
    }
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-20 z-[100] padding-container flex items-center justify-center">
          <form
            onSubmit={handleSubmit}
            className={`p-5 mg:p-[30px] lg:p-[50px] xl:p-[70px] w-[350px] md:w-[600px] lg:w-[900px] xl:w-[1054px] drop-shadow-2xl  border-[2px] border-white bg-gray-80 bg-opacity-90 rounded-lg`}
            noValidate
          >
            <div className="flex items-center justify-between text-center font-sans font-normal text-[42px] leading-[76px]">
              <h1 className="text-blue-420 lg:pb-10 text-[20px] md:text-[30px] lg:text-[46px] lg:leading-[40px] font-normal font-sans">
                Apply
              </h1>
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  onClose();
                }}
                className="text-gray-500 hover:text-black text-4xl font-bold"
              >
                &times;
              </button>
            </div>
            <div>
              <div className="lg:flex lg:items-center lg:justify-between lg:gap-6 mb-10">
                <div className="relative min-w-[317px] md:min-w-[442px] lg:min-w-[380px] xl:min-w-[442px] mb-6 lg:mb-0">
                  <input
                    type="text"
                    id="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className={`${isMobile ? 'mobile-form-container' : 'form-container'} input-placeholder shadow-md ${formErrors.firstName ? 'outline-red-300' : 'outline-blue-250'}`}
                    autoComplete="off"
                    placeholder="First Name"
                  />
                  {formErrors.firstName && (
                    <div className="form-error-msg">{formErrors.firstName}</div>
                  )}
                </div>
                <div className="relative min-w-[317px] md:min-w-[442px] lg:min-w-[380px] xl:min-w-[442px]">
                  <input
                    type="text"
                    id="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className={`${isMobile ? 'mobile-form-container' : 'form-container'} input-placeholder shadow-md ${formErrors.lastName ? 'outline-red-300' : 'outline-blue-250'}`}
                    autoComplete="off"
                    placeholder="Last Name"
                  />
                  {formErrors.lastName && (
                    <div className="form-error-msg">{formErrors.lastName}</div>
                  )}
                </div>
              </div>
              <div className="lg:flex lg:items-center lg:justify-between lg:gap-6 mb-6 lg:mb-10">
                <div className="relative min-w-[317px] md:min-w-[442px] lg:min-w-[380px] xl:min-w-[442px] mb-6 lg:mb-0">
                  <PhoneInput
                    forceDialCode={true}
                    defaultCountry="au"
                    countrySelectorStyleProps={{
                      buttonStyle: {
                        zIndex: '30',
                        position: 'absolute',
                        left: '0px',
                        height: '38px',
                        width: '56px',
                        border: 'none',
                        backgroundColor: `${isMobile ? '#EBF7FF' : 'bg-white'}`,
                      },
                    }}
                    onChange={(phone) =>
                      setFormData({ ...formData, phoneNumber: phone })
                    }
                    inputProps={{
                      id: 'phoneNumber',
                      autoComplete: 'off',
                      className: `w-full relative pl-16 focus:outline-none mb-[2px] ${isMobile ? 'bg-blue-20' : 'bg-white'}`,
                    }}
                    className={`${isMobile ? 'mobile-form-container' : 'form-container'} lg:bg-white input-placeholder ${formErrors.phoneNumber ? 'outline-red-300' : 'outline-blue-250'}`}
                    value={formData.phoneNumber}
                  />
                  {formErrors.phoneNumber && (
                    <div className="form-error-msg">
                      {formErrors.phoneNumber}
                    </div>
                  )}
                </div>
                <div className="relative min-w-[317px] md:min-w-[442px] lg:min-w-[380px] xl:min-w-[442px]">
                  <input
                    type="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={`${isMobile ? 'mobile-form-container' : 'form-container'} input-placeholder shadow-md ${formErrors.email ? 'outline-red-300' : 'outline-blue-250'}`}
                    autoComplete="off"
                    placeholder="Email"
                  />
                  {formErrors.email && (
                    <div className="form-error-msg">{formErrors.email}</div>
                  )}
                </div>
              </div>
              <div className="lg:flex lg:items-center lg:justify-between lg:gap-6  mb-6 lg:mb-10">
                <div className="relative min-w-[317px] md:min-w-[442px] lg:min-w-[380px] xl:min-w-[442px] mb-6 lg:mb-0">
                  <div className="relative min-w-[317px] md:min-w-[442px] lg:min-w-[380px] xl:min-w-[442px] bg-white">
                    <ProfessionSelector
                      onChange={(value) =>
                        setFormData({ ...formData, profession: value })
                      }
                      professionId={profession}
                      // error={formErrors.profession}
                    />
                    {/* {formErrors.profession && (
                      <div className="form-error-msg">
                        {formErrors.profession}
                      </div>
                    )} */}
                  </div>
                </div>
                <div className="relative min-w-[317px] md:min-w-[442px] lg:min-w-[380px] xl:min-w-[442px]">
                  <div
                    className={`${isMobile ? 'mobile-form-container' : 'form-container'} bg-white ${formErrors.fileUpload ? 'outline-red-300' : 'outline-blue-250'} flex items-center justify-center w-full shadow-md`}
                    onChange={handleFileChange}
                  >
                    <label
                      htmlFor="fileUpload"
                      className=" w-full cursor-pointer"
                    >
                      <div className=" ">
                        {file ? (
                          <div className="flex items-center text-nowrap justify-center">
                            <div>{getIconForFileType(file.name)}</div>
                            <h1 className="form-text">{file.name}</h1>
                          </div>
                        ) : (
                          <p className="form-text mt-[6px]">
                            <div className="flex items-center justify-between m-2">
                              <span className="">Upload Your CV</span>
                              <img
                                src={`${AWS_BASE_ASSETS_ICONS}CloudUploadFill.png`}
                                width={23}
                                height={23}
                              />
                            </div>
                          </p>
                        )}
                      </div>
                      <input
                        id="fileUpload"
                        type="file"
                        className="hidden"
                        onChange={handleFileChange}
                      />
                    </label>
                  </div>
                  {formErrors.fileUpload && (
                    <div className="form-error-msg">
                      {formErrors.fileUpload}
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-6">
                <div className="mb-3 md:mb-5 mt-10">
                  <div className="relative">
                    <input
                      type="checkbox"
                      id="agree"
                      checked={formData.agree}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="agree"
                      className={`relative text-xs lg:text-sm text-gray-900 ${
                        formErrors.agree
                          ? 'after:text-red-100'
                          : 'after:text-gray-900'
                      } after:content-['*'] after:ml-1  absolute top-0 left-3`}
                    >
                      I Agree to the{' '}
                      <Link
                        to={'/terms-and-conditions'}
                        className="text-blue-220"
                      >
                        Term and Condition
                      </Link>{' '}
                      and{' '}
                      <Link to={'/privacy-policy'} className="text-blue-220">
                        Privacy policy
                      </Link>
                    </label>
                    {formErrors.agree && (
                      <div className="absolute text-red-100 text-sm ml-5">
                        {formErrors.agree}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="subscribe"
                    checked={formData.subscribe}
                    onChange={handleChange}
                    className=""
                    required
                  />
                  <label
                    htmlFor="subscribe"
                    className="ml-2 text-xs lg:text-sm text-gray-900"
                  >
                    Subscribe for Job Alert
                  </label>
                </div>
              </div>
              <div className="flex items-center lg:justify-end justify-center mt-5 lg:mt-0">
                <div className="w-[317px] md:w-full lg:w-[220px]">
                  <SubmitButton
                    type="submit"
                    title="Apply Now"
                    variant={`${renderBtnColor()}`}
                    isSubmitting={isSubmitting}
                    disableVariant="find-jobs-btn-candidate-disabled"
                    submitTitle="Applying"
                  />
                </div>
              </div>
            </div>
          </form>
          {submit && (
            <PopupNotification
              title={'Application Successfully Submitted'}
              message={
                'Thank you for applying. Please verify yourself by clicking on the verification link in your email.'
              }
              onClose={() => setSubmit(false)}
              type={'success'}
            />
          )}
        </div>
      )}
    </>
  );
}
