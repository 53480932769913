import shareIcon from '@/assets/icons/MainShare.png';
import Button from '@/components/shared/button/Button';
import applyNow from '@/assets/icons/ApplyNow.png';
import { useNavigate } from 'react-router-dom';
import { AWS_BASE_ASSETS_ICONS } from '@/config/seviceApiConfig';
import { formatJobNameForURL } from '@/utils/formatJobNameforURL';
import calculateDaysDifference from '@/utils/getNoOfDatesUtil';
import { getDisplayText } from '@/utils/handleUndefinedUtil';

export default function MainJobCard({ data, onShareClick }) {
  const navigate = useNavigate();

  const handleApplyNow = (event) => {
    // localStorage.setItem('jobData', JSON.stringify(data));
    event.stopPropagation();
    const jobUrl = formatJobNameForURL(data.job_title);
    if (data.is_permanent === '1') {
      navigate(`/apply-now/permanent/${jobUrl}/${data.jobdetails_id}`, {
        state: { jobData: data },
      });
    } else {
      navigate(`/apply-now/locum/${jobUrl}/${data.jobdetails_id}`, {
        state: { jobData: data },
      });
    }
    window.scrollTo(0, 0);
  };

  const handleShareButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    const jobUrl = formatJobNameForURL(data.job_title);
    const shareUrl =
      data.is_permanent === '1'
        ? `${window.location.origin}/apply-now/permanent/${jobUrl}/${data.jobdetails_id}`
        : `${window.location.origin}/apply-now/locum/${jobUrl}/${data.jobdetails_id}`;
    onShareClick(shareUrl);
  };

  return (
    <>
      <div
        className="bg-white shadow-md lg:min-w-[850px] xl:max-w-[880px] 2xl:max-w-[900px] 4xl:max-w-[980px] h-auto p-4 border-[2px] border-gray-320 rounded-md relative cursor-pointer"
        onClick={handleApplyNow}
      >
        <div className="px-3 rounded-[10px] 2xl:w-[880px] 4xl:w-[920px]">
          <div className="flex items-center justify-between mb-3">
            <h3 className="text-gray-480 font-semibold bg-gray-30 border rounded-md border-gray-120 text-center px-[6px] text-sm">
              {data.job_id}
            </h3>
            <div className="cursor-pointer" onClick={handleShareButtonClick}>
              <img src={shareIcon} className="cursor-pointer" />
            </div>
          </div>
          <h3 className="font-sans font-bold text-[22px] leading-[24px] text-blue-700 4xl:min-w-[400px]  mt-2">
            {data.job_title}
          </h3>
          <div className="flex items-center mt-1">
            <div className="h-[30px] w-[30px] rounded-full flex items-center justify-start">
              <img
                src={`${AWS_BASE_ASSETS_ICONS}mainLocation.png`}
                alt="Salary Icon"
                className="w-[16px] h-[19px] object-contain"
              />
            </div>
            <p className="text-gray-700 text-base">
              {getDisplayText(data.suburb?.name, data.state?.name)}
            </p>
          </div>
        </div>
        <div className="px-3 mt-1">
          <p className="font-satoshi text-base leading-[22px] font-normal text-gray-700 line-clamp-3">
            {data.job_brief}
          </p>
        </div>
        <div className="flex items-center justify-between mt-4 px-3">
          <p className="text-gray-700 text-base">
            Posted{' '}
            {calculateDaysDifference(data.commencement_date) === 0
              ? 'today'
              : `${calculateDaysDifference(data.commencement_date)} ${
                  calculateDaysDifference(data.commencement_date) === 1
                    ? 'day ago'
                    : 'days ago'
                }`}
          </p>
          <div className="w-[182px]">
            <Button
              type="submit"
              title="View"
              variant="find-jobs-main-btn"
              iconSrc={applyNow}
              handleClick={handleApplyNow}
            />
          </div>
        </div>
      </div>
    </>
  );
}
