import {
  Button,
  CommonMobileHero,
  DropDownSection,
  GratitudeComponent,
  HeroBackground,
  MainJobCard,
} from '@/components';
import shareIcon from '@/assets/icons/MainShare.png';
import applyNow from '@/assets/icons/blueApplyNow.png';
import { MobileLayout, PageLayout } from '@/layout';
import { useEffect, useState } from 'react';
import useResponsive from '@/hooks/useResponsive';
import MainMobileJobCard from '@/components/mobile/cards/MainMobileJobCard';
import { useLocation, useNavigate } from 'react-router-dom';
import useFetchAllProfessions from '@/components/forms/selectors/hook/useFetchAllProfessions';
import useFetchAllState from '@/components/forms/selectors/hook/useFetchAllStates';
import { formatURLNameToNormal } from '@/utils/formatNameUtil';
import PermanentJobFilter from '@/components/filter/PermanentJobFilter';
import LocumJobFilter from '@/components/filter/LocumJobFilter';
import NoJobsCard from '@/components/noData/NoJobsCard';
import LoadingComponent from '@/components/noData/LoadComponent';
import { formatNameForURL } from '@/utils/formatNameForURL';
import useFetchSpecialtyProfessionWise from '@/components/forms/selectors/hook/useFetchSpecialtyProfessionWise';
import useFetchSeniorityProfessionWise from '@/components/forms/selectors/hook/useFetchAllSeniorityProfessionWise';
import useFetchFilterData from '@/hooks/useFetchFilterMenuData';
import PageLoadingComponent from '@/components/noData/PageLoadingComponent';
import FilterIcon from '/icons/Filter.png';
import { useStateContext } from '@/context/ContextProvider';
import ShareModal from '@/components/models/ShareModal';
import { motion } from 'framer-motion';
import useFetchBothProfessionAndStateJobDetails from '@/hooks/useFetchBothProfessionAnsState';
import NewCardComponent from '@/components/home/newBanner/NewCardComponent';
import MainMobileJobCardSkeleton from '@/components/noData/MobileLoadingComponent';
import useFetchFrequentQuestions from '@/hooks/useFetchFrequentQuestions';
import { getProductionUrl } from '@/config/getProductionUrl';
import { formatJobNameForURL } from '@/utils/formatJobNameforURL';
import Pagination from '@/components/pagination/Pagination';
import SeoOptimized from '@/components/reactHelmet/SeoOptimized';
import useFetchAllSeoDetails from '@/hooks/useFetchAllSeoDetails';

const professionFromLocalStorage = JSON.parse(
  localStorage.getItem('professionId') || 'null'
);

export default function AllJobMain() {
  const CurLocation = useLocation();
  const matches = CurLocation.pathname.match(
    /\/(permanent|locum)-jobs\/([^/]+)\/([^/]+)-jobs/
  );
  const jobTypeFromURL = matches ? matches[1] : 'permanent';
  const location = matches ? matches[2] : '';
  const profession = matches ? matches[3] : '';
  const specialty = matches ? matches[3] : '';
  const seniority = matches ? matches[3] : '';

  const productionUrl = getProductionUrl();

  const navigate = useNavigate();
  const { isMobile, screenSize } = useResponsive();
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  const [jobUrl, setJobUrl] = useState<string>('');
  const { allProfessionsData, professionDataLoading } =
    useFetchAllProfessions();
  const { allStates, stateLoading } = useFetchAllState();
  const [professionId, setProfessionId] = useState(professionFromLocalStorage);
  const [specialtyId, setSpecialtyId] = useState<number>();
  const [seniorityId, setSeniorityId] = useState<number>();
  const [stateId, setStateId] = useState<number>();
  const [suburbId, setSuburbId] = useState<number>();
  const [divisionId, setDivisionId] = useState<number>();
  const [regionId, setRegionId] = useState<number>();
  const { filterData, FilterDataLoading, FilterDataError } =
    useFetchBothProfessionAndStateJobDetails({
      divisionId,
      professionId,
      stateId,
      specialtyId,
      seniorityId,
      regionId,
      suburbId,
    });

  const [visibleJobs, setVisibleJobs] = useState<number>(6);
  const [selectedTopic, setSelectedTopic] = useState(
    jobTypeFromURL === 'permanent' ? 'Permanent' : 'Locum'
  );
  const { filterMenuData, filterMenuLoading } = useFetchFilterData();
  const { specialtiesProfessionWise, specialtyLoading } =
    useFetchSpecialtyProfessionWise(professionId);
  const { senioritiesProfessionWise, seniorityLoading } =
    useFetchSeniorityProfessionWise(professionId);
  const { isOffCanvasOpen, openOffCanvas } = useStateContext();
  const [description, setDescription] = useState('');
  const [mainTitle, setMainTitle] = useState('');
  const { allFaqData } = useFetchFrequentQuestions();
  const relatedFAQ = allFaqData?.filter((page) => {
    page.page_url === `${productionUrl}${CurLocation.pathname}`;
  });

  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 10;

  // Calculate the jobs to display based on pagination
  const startIndex = (currentPage - 1) * jobsPerPage;
  const endIndex = startIndex + jobsPerPage;

  const handleShareClick = (url: string) => {
    setJobUrl(url);
    setIsShareModalOpen(true);
  };

  const handleCloseShareModal = () => {
    setIsShareModalOpen(false);
  };

  const handleTopicClick = (selectedTopic) => {
    setSelectedTopic(selectedTopic);
    updateUrl(professionId, stateId, specialtyId, seniorityId, selectedTopic);
  };

  const handleProfessionChange = (professionId) => {
    setProfessionId(professionId);
    updateUrl(professionId, stateId, specialtyId, seniorityId, selectedTopic);
  };

  const handleStateChange = (stateId) => {
    setStateId(stateId);
    updateUrl(professionId, stateId, specialtyId, seniorityId, selectedTopic);
  };

  const handleSpecialtyChange = (specialtyId) => {
    setSpecialtyId(specialtyId);
    updateUrl(professionId, stateId, specialtyId, seniorityId, selectedTopic);
  };

  const handleSeniorityChange = (seniorityId) => {
    setSeniorityId(seniorityId);
    updateUrl(professionId, stateId, specialtyId, seniorityId, selectedTopic);
  };

  const handleRegionChange = (regionId) => {
    setRegionId(regionId);
  };

  const handleSuburbChange = (suburbId) => {
    setSuburbId(suburbId);
  };

  const handleDivisionChange = (divisionId) => {
    setDivisionId(divisionId);
  };

  useEffect(() => {
    if (specialtyId !== undefined) {
      updateUrl(professionId, stateId, specialtyId, seniorityId, selectedTopic);
    }
  }, [specialtyId]);

  useEffect(() => {
    if (seniorityId !== undefined) {
      updateUrl(professionId, stateId, specialtyId, seniorityId, selectedTopic);
    }
  }, [seniorityId]);

  useEffect(() => {
    if (!matches) {
      return () => {
        localStorage.removeItem('professionId');
      };
    }
  }, [CurLocation.pathname, matches]);

  const specialtyName = specialtiesProfessionWise?.find(
    (specialty) => specialty.specialities_id === specialtyId
  )?.name;

  const seniorityName = senioritiesProfessionWise?.find(
    (seniority) => seniority.seniorities_id === seniorityId
  )?.name;

  const updateUrl = (
    professionId,
    stateId,
    specialtyId,
    seniorityId,
    topic
  ) => {
    const professionName = allProfessionsData?.find(
      (p) => p.profession_id === professionId
    )?.name;
    const stateName = allStates?.find((s) => s.state_id === stateId)?.name;
    let formattedName;
    let path = `/${topic.toLowerCase()}-jobs`;

    if (stateName) {
      path += `/${formatNameForURL(stateName)}`;
    }

    if (professionName) {
      formattedName = formatNameForURL(professionName);
    }

    if (specialtyName) {
      formattedName = formatNameForURL(specialtyName);
    }

    if (seniorityName) {
      formattedName = formatNameForURL(seniorityName);
    }

    if (formattedName) {
      path += `/${formattedName}-jobs`;
    }

    navigate(path);
  };
  useEffect(() => {
    if (profession) {
      const professionData = allProfessionsData?.find(
        (prof) => prof.name === formatURLNameToNormal(profession)
      );
      if (professionData) {
        setProfessionId(professionData.profession_id);
        setDescription(professionData.description);
        setMainTitle(professionData.title);
      }
    }
  }, [profession, allProfessionsData]);

  // useEffect(() => {
  //   if (CurLocation.state) {
  //     const { selectedState, selectedProfession } = CurLocation.state;
  //     if (selectedState) {
  //       setStateId(parseInt(selectedState));
  //     }
  //     if (selectedProfession) {
  //       setProfessionId(parseInt(selectedProfession));
  //     }
  //   }
  // }, [CurLocation]);

  useEffect(() => {
    if (specialty) {
      const specialtyData = specialtiesProfessionWise?.find(
        (spec) => formatNameForURL(spec.name) === specialty
      );
      if (specialtyData) {
        setSpecialtyId(specialtyData.specialities_id);
        localStorage.setItem('professionId', specialtyData.profession_id);
      }
    }
  }, [specialty, senioritiesProfessionWise]);

  useEffect(() => {
    if (seniority) {
      const seniorityData = senioritiesProfessionWise?.find(
        (sen) => formatNameForURL(sen.name) === seniority
      );
      if (seniorityData) {
        setSeniorityId(seniorityData.seniorities_id);
        localStorage.setItem('professionId', seniorityData.profession_id);
      }
    }
  }, [seniority, senioritiesProfessionWise]);

  useEffect(() => {
    if (location) {
      const stateData = allStates?.find(
        (st) => st.name === formatURLNameToNormal(location)
      );
      const stateID = stateData?.state_id;
      if (stateData) {
        setStateId(stateID);
      }
    }
  }, [location, allStates]);

  const filteredData = filterData?.filter((job) => {
    if (selectedTopic === 'Permanent') {
      return job.is_permanent === '1';
    } else {
      return job.is_permanent !== '1';
    }
  });

  if (filterMenuLoading || professionDataLoading) {
    return <PageLoadingComponent />;
  }

  if (FilterDataError) {
    return <div>Error: {FilterDataError.message}</div>;
  }

  const handleLoadMore = () => {
    setVisibleJobs((prevCount) => prevCount + 6);
  };

  const totalPages = Math.ceil(filteredData?.length / jobsPerPage);

  const displayedJobs = isMobile
    ? filteredData?.slice(0, visibleJobs) || []
    : filteredData?.slice(startIndex, endIndex);

  const jobCardVariants = {
    hidden: { x: -100, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: { type: 'spring', stiffness: 50, damping: 20, duration: 0.5 },
    },
  };

  return (
    <>
      {isMobile ? (
        <>
          <CommonMobileHero
            MainTitle={mainTitle}
            SubTitle={`${formatURLNameToNormal(profession)} Jobs in ${formatURLNameToNormal(location)}`}
            Paragraph={''}
          />
          <MobileLayout>
            <div>
              <div className="py-[1.125rem] md:py-8">
                <div className="flex items-center justify-end">
                  <Button
                    type="button"
                    title="Filter & Sort"
                    variant="filter-btn"
                    iconSrc={FilterIcon}
                    handleClick={openOffCanvas}
                  />
                </div>
              </div>
              <div
                className={`absolute top-[65px] inset-0 z-[8] bg-gray-900 bg-opacity-50 ${isOffCanvasOpen ? 'block' : 'hidden'}`}
              >
                <div>
                  <div className="flex items-center justify-between">
                    <div className="w-[290px] md:w-[370px] lg:w-[480px] flex items-center shadow-md font-sans text-[20px] leading-[23px] lg:text-[26px] lg:leading-[32px] font-normal">
                      <h1
                        className={`w-1/2 text-center py-[14px] lg:py-5 ${
                          selectedTopic === 'Permanent'
                            ? 'bg-white'
                            : 'bg-gray-420'
                        } cursor-pointer`}
                        onClick={() => handleTopicClick('Permanent')}
                      >
                        Permanent
                      </h1>
                      <h1
                        className={`w-1/2 text-center py-[14px] lg:py-5 ${
                          selectedTopic === 'Locum' ? 'bg-white' : 'bg-gray-420'
                        } cursor-pointer`}
                        onClick={() => handleTopicClick('Locum')}
                      >
                        Locum
                      </h1>
                    </div>
                  </div>
                  <div className="flex items-center justify-start">
                    {selectedTopic === 'Permanent' ? (
                      <PermanentJobFilter
                        data={filterMenuData?.division}
                        allStates={filterMenuData?.state}
                        professionId={professionId}
                        stateId={stateId}
                        specialtyId={specialtyId}
                        seniorityId={seniorityId}
                        onProfessionChange={handleProfessionChange}
                        onSpecialtyChange={handleSpecialtyChange}
                        onSeniorityChange={handleSeniorityChange}
                        onStateChange={handleStateChange}
                        onSuburbSelect={handleSuburbChange}
                        suburbId={suburbId}
                        regionId={regionId}
                        divisionId={divisionId}
                        onDivisionChange={handleDivisionChange}
                        onRegionChange={handleRegionChange}
                      />
                    ) : (
                      <LocumJobFilter
                        data={filterMenuData?.division}
                        allStates={filterMenuData?.state}
                        professionId={professionId}
                        stateId={stateId}
                        specialtyId={specialtyId}
                        seniorityId={seniorityId}
                        onProfessionChange={handleProfessionChange}
                        onSpecialtyChange={handleSpecialtyChange}
                        onSeniorityChange={handleSeniorityChange}
                        onStateChange={handleStateChange}
                        onSuburbSelect={handleSuburbChange}
                        suburbId={suburbId}
                        regionId={regionId}
                        divisionId={divisionId}
                        onDivisionChange={handleDivisionChange}
                        onRegionChange={handleRegionChange}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {FilterDataLoading ? (
              <div className="flex items-center justify-center">
                <MainMobileJobCardSkeleton />
              </div>
            ) : (
              <div className="flex items-center justify-center gap-x-5">
                <div>
                  <div className="flex items-center justify-center">
                    {filteredData?.length === 0 ? (
                      <div className="">
                        <NoJobsCard selectedTopic={selectedTopic} />
                      </div>
                    ) : (
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-6">
                        {displayedJobs?.map((job, index) => (
                          <div key={index}>
                            <MainMobileJobCard data={job} />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="text-center my-10">
                    {filteredData?.length > visibleJobs && (
                      <button
                        onClick={handleLoadMore}
                        className="text-blue-390 text-center text-[20px] leading-[23px]"
                      >
                        Load More...
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </MobileLayout>
        </>
      ) : (
        <>
          <HeroBackground
            title={mainTitle}
            description={`${formatURLNameToNormal(profession)} Jobs in ${formatURLNameToNormal(location)}`}
            icon={''}
          />
          <PageLayout>
            <div className="flex items-start justify-between gap-x-5 padding-container">
              <>
                <div className="">
                  <div className="">
                    <div className="lg:w-[350px] xl:w-[380px] 2xl:w-[400px] 4xl:w-[450px] bg-white p-2 flex items-center gap-x-2 shadow-md font-sans text-[20px] leading-[23px] font-normal">
                      <h2
                        className={`w-1/2 text-center py-[14px] lg:py-3 bg-gray-30 rounded-md ${
                          selectedTopic === 'Permanent'
                            ? 'border-b-[4px] border-green-100'
                            : 'border-b-[4px] border-transparent'
                        } cursor-pointer`}
                        onClick={() => handleTopicClick('Permanent')}
                      >
                        Permanent
                      </h2>
                      <h2
                        className={`w-1/2 text-center py-[14px] lg:py-3 bg-gray-30 rounded-md ${
                          selectedTopic === 'Locum'
                            ? 'border-b-[4px] border-green-100'
                            : 'border-b-[4px] border-transparent'
                        } cursor-pointer`}
                        onClick={() => handleTopicClick('Locum')}
                      >
                        Locum
                      </h2>
                    </div>
                    <div className="flex items-center justify-start">
                      {selectedTopic === 'Permanent' ? (
                        <PermanentJobFilter
                          data={filterMenuData?.division}
                          allStates={filterMenuData?.state}
                          professionId={professionId}
                          stateId={stateId}
                          specialtyId={specialtyId}
                          seniorityId={seniorityId}
                          onProfessionChange={handleProfessionChange}
                          onSpecialtyChange={handleSpecialtyChange}
                          onSeniorityChange={handleSeniorityChange}
                          onStateChange={handleStateChange}
                          onSuburbSelect={handleSuburbChange}
                          suburbId={suburbId}
                          regionId={regionId}
                          divisionId={divisionId}
                          onDivisionChange={handleDivisionChange}
                          onRegionChange={handleRegionChange}
                        />
                      ) : (
                        <LocumJobFilter
                          data={filterMenuData?.division}
                          allStates={filterMenuData?.state}
                          professionId={professionId}
                          stateId={stateId}
                          specialtyId={specialtyId}
                          seniorityId={seniorityId}
                          onProfessionChange={handleProfessionChange}
                          onSpecialtyChange={handleSpecialtyChange}
                          onSeniorityChange={handleSeniorityChange}
                          onStateChange={handleStateChange}
                          onSuburbSelect={handleSuburbChange}
                          suburbId={suburbId}
                          regionId={regionId}
                          divisionId={divisionId}
                          onDivisionChange={handleDivisionChange}
                          onRegionChange={handleRegionChange}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
              <div>
                {FilterDataLoading ? (
                  <div className="flex items-center justify-center">
                    <LoadingComponent />
                  </div>
                ) : (
                  <>
                    {selectedTopic !== 'Permanent' &&
                    displayedJobs.length > 0 ? (
                      <table className="w-full border-collapse border border-gray-300 text-[20px]">
                        <thead>
                          <tr className="border-b-[2px] border-gray-5 text-gray-50">
                            <th className="font-normal p-5 text-start"></th>
                            <th className="font-normal p-5 text-start">
                              Position
                            </th>
                            <th className="font-normal p-5 text-start">
                              Period
                            </th>
                            <th className="font-normal p-5 text-start">Rate</th>
                            <th className="font-normal p-5 text-start">
                              Location
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {displayedJobs.map((job, idx) => {
                            const startDateHighlight = job.highlights.find(
                              (highlight) => highlight.label === 'Start Date'
                            );

                            const endDateHighlight = job.highlights.find(
                              (highlight) => highlight.label === 'End Date'
                            );

                            const isToBeDiscussed =
                              startDateHighlight?.name === 'To be discussed' &&
                              endDateHighlight?.name === 'To be discussed';

                            return (
                              <tr
                                key={idx}
                                className={`p-5 relative cursor-pointer border-t items-start border-b border-gray-5 ${
                                  idx % 2 === 0 ? 'bg-white' : 'bg-gray-300'
                                }`}
                                onClick={() =>
                                  navigate(
                                    `/apply-now/locum/${formatJobNameForURL(
                                      job.job_title
                                    )}/${job.jobdetails_id}`
                                  )
                                }
                              >
                                <td
                                  className="py-10 px-5 font-sans font-normal text-base text-blue-700"
                                  style={{ verticalAlign: 'top' }}
                                >
                                  {job.job_id}
                                </td>
                                <td
                                  className="py-10 px-5 font-sans font-bold text-base text-blue-700 w-[290px]"
                                  style={{ verticalAlign: 'top' }}
                                >
                                  {job.job_title}
                                  <br />
                                  <span className="font-sans font-normal text-base mt-1">
                                    {job?.profession?.name}
                                  </span>
                                </td>
                                <td
                                  className="py-10 px-5 font-sans font-normal text-base text-blue-700  w-[210px]"
                                  style={{ verticalAlign: 'top' }}
                                >
                                  {isToBeDiscussed ? (
                                    'To be discussed'
                                  ) : (
                                    <>
                                      <div>{startDateHighlight?.name}</div>
                                      <div>{endDateHighlight?.name}</div>
                                    </>
                                  )}
                                </td>
                                <td
                                  className="py-10 px-5 font-sans font-normal text-base text-blue-700"
                                  style={{ verticalAlign: 'top' }}
                                >
                                  {job.billing_share && job.hourly_fee ? (
                                    <>
                                      <p className="line-clamp-1">
                                        {job.hourly_fee} ,
                                      </p>
                                      <p className="line-clamp-1">
                                        {job.billing_share}
                                      </p>
                                    </>
                                  ) : (
                                    <p className="">
                                      {job.hourly_fee || job.billing_share}
                                    </p>
                                  )}
                                </td>
                                <td
                                  className="py-10 p-5 font-sans font-normal text-base text-blue-700"
                                  style={{ verticalAlign: 'top' }}
                                >
                                  {job.suburb?.name && (
                                    <p>{job.suburb.name},</p>
                                  )}
                                  {job.state?.name && <p>{job.state.name}</p>}
                                </td>
                                <div
                                  className="cursor-pointer absolute right-5 top-4"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    const jobUrl = `${window.location.origin}/apply-now/locum/${formatJobNameForURL(
                                      job.job_title
                                    )}/${job.jobdetails_id}`;
                                    handleShareClick(jobUrl);
                                  }}
                                >
                                  <img
                                    src={shareIcon}
                                    className="cursor-pointer w-[18px] h-[18px]"
                                  />
                                </div>
                                {/* Apply Now Button */}
                                <div className="absolute bottom-2 right-5">
                                  <Button
                                    type="button"
                                    title="Apply Now"
                                    variant="apply-now"
                                    iconSrc={applyNow}
                                    handleClick={(event) => {
                                      event.stopPropagation();
                                      navigate(
                                        `/apply-now/locum/${formatJobNameForURL(
                                          job.job_title
                                        )}/${job.jobdetails_id}`
                                      );
                                    }}
                                  />
                                </div>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <div className="flex flex-col gap-y-6">
                        {displayedJobs.length === 0 ? (
                          <NoJobsCard selectedTopic={selectedTopic} />
                        ) : (
                          displayedJobs.map((job, index) => (
                            <div key={index}>
                              <MainJobCard
                                data={job}
                                onShareClick={handleShareClick}
                              />
                            </div>
                          ))
                        )}
                      </div>
                    )}

                    {filteredData?.length > 0 && (
                      <div className="text-center mt-10">
                        <div className="my-10">
                          <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="px-28">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.5 }}
                className="text-center font-sans font-normal text-[33px] lg:text-[24px] leading-[48px] lg:leading-[36px] text-blue-350 padding-container"
              >
                {description?.split('').map((char, index) => (
                  <motion.span
                    key={index}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 + index * 0.01 }}
                  >
                    {char}
                  </motion.span>
                ))}
              </motion.div>
            </div>
            {/* <Register /> */}
            {relatedFAQ && relatedFAQ.length > 0 && (
              <DropDownSection FAQ={relatedFAQ} />
            )}
          </PageLayout>
          <PageLayout>
            <GratitudeComponent />
            <NewCardComponent />
            {/* <InsightComponent /> */}
          </PageLayout>
        </>
      )}
      <div className={`modal ${isShareModalOpen ? 'modal-open' : ''}`}>
        <ShareModal
          isOpen={isShareModalOpen}
          onClose={handleCloseShareModal}
          jobUrl={jobUrl}
        />
      </div>
    </>
  );
}
