import React, { useEffect } from 'react';
import JobFinder from '@/components/home/findJobs/JobFinder';
import { AWS_BASE_URL_ASSETS } from '@/config/seviceApiConfig';
import { motion, useAnimation } from 'framer-motion';

const Hero = ({ totalCount }) => {
  // Animation controls for each logo
  const leftLogoControls = useAnimation();
  const rightLogoControls = useAnimation();

  const textVariant = {
    initial: {
      x: -500,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 1,
        staggerChildren: 0.2,
      },
    },
  };

  const leftLogoVariant = {
    initial: {
      y: 100, // Start from below the view
      opacity: 0,
    },
    animate: {
      y: 0, // Move to its original position
      opacity: 1,
      transition: {
        duration: 1,
        ease: 'easeInOut',
      },
    },
  };

  const rightLogoVariant = {
    initial: {
      y: -100, // Start from above the view
      opacity: 0,
    },
    animate: {
      y: 0, // Move to its original position
      opacity: 1,
      transition: {
        duration: 1,
        ease: 'easeInOut',
      },
    },
  };

  useEffect(() => {
    // Animation for left logo
    leftLogoControls.start({
      scale: [0.9, 1.1],
      transition: {
        repeat: Infinity,
        repeatType: 'mirror',
        duration: 8,
        ease: 'easeInOut',
      },
    });

    rightLogoControls.start({
      scale: [1.1, 0.9],
      transition: {
        repeat: Infinity,
        repeatType: 'mirror',
        duration: 8,
        ease: 'easeInOut',
      },
    });
  }, [leftLogoControls, rightLogoControls]);

  return (
    <section className="hero-section max-width-container bg-blue-500 relative overflow-hidden">
      <div className="main-width-container">
        <div className="flex  justify-between h-[520px] mt-24">
          <motion.div
            variants={textVariant}
            initial="initial"
            animate="animate"
          >
            <motion.h1
              className="hero-text-main w-[750px] font-sans"
              variants={textVariant}
            >
              Find Your Dream Job in Australian Healthcare
            </motion.h1>
            <motion.div className="mt-5  w-[802px]" variants={textVariant}>
              <JobFinder totalCount={totalCount} />
            </motion.div>
          </motion.div>

          <div className="relative flex mt-10">
            <motion.div
              className="hero-img-container relative"
              initial="initial"
              animate="animate"
              variants={leftLogoVariant}
            >
              <motion.img
                src={`${AWS_BASE_URL_ASSETS}/award.png`}
                width={260}
                height={220}
                alt="hero"
                loading="lazy"
                className="rounded-full object-cover object-center"
                animate={leftLogoControls} // Apply left logo animation controls
              />
            </motion.div>

            <motion.div
              className="hero-img-container relative"
              initial="initial"
              animate="animate"
              variants={rightLogoVariant}
            >
              <motion.img
                src={`${AWS_BASE_URL_ASSETS}/new_award_logo.png`}
                width={220}
                height={220}
                alt="hero"
                loading="lazy"
                className="rounded-full object-cover object-center"
                animate={rightLogoControls}
              />
            </motion.div>
          </div>
        </div>
      </div>
      <div className="waves"></div>
    </section>
  );
};

export default Hero;
