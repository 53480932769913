import { AWS_BASE_ASSETS_ICONS } from '@/config/seviceApiConfig';
import { useNavigate } from 'react-router-dom';

const ConsultationPopup = ({ isVisible, onClose }) => {
  const navigate = useNavigate();

  const handleCandidateClick = () => {
    navigate('/candidate-consultation');
    onClose();
  };

  const handleClientClick = () => {
    navigate('/employer-consultation');
    onClose();
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ">
      <div className="bg-white border border-white bg-opacity-95 w-[700px] h-[450px] p-10 rounded-lg shadow-lg text-center">
        <div className="flex items-center justify-end">
          <button className="" onClick={onClose}>
            <img
              src={`${AWS_BASE_ASSETS_ICONS}LocumCloseBtn.png`}
              alt="close Icon"
              className="w-5 h-5"
            />
          </button>
        </div>
        <h2 className="text-[24px] leading-[28px] font-sans font-normal mb-4 mt-10">
          Looking for your next opportunity or hiring top talent?
        </h2>
        <div className="mt-16">
          <div>
            <button
              className="bg-gradient-to-r from-blue-400 to-blue-150 text-white shadow-lg rounded-[4px] py-[15px] font-sans font-normal text-[20px] w-[542px] text-center"
              onClick={handleCandidateClick}
            >
              Candidate
            </button>
          </div>
          <div className="mt-5">
            <button
              className="bg-gradient-to-r from-blue-500 to-blue-550 text-white shadow-lg rounded-[4px] py-[15px] font-sans font-normal text-[20px] w-[542px] text-center"
              onClick={handleClientClick}
            >
              Employer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsultationPopup;
