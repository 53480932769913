import { BottomNavbar, TopNavbar } from '@/components';
import { useScrollHandler } from '@/hooks/useScrollHandler';
import { matchPath, useLocation } from 'react-router-dom';

export default function DynamicHeaderLayout({
  stateWithRegions,
  fetchStatesWithRegions,
  bottomMenuData,
}) {
  const scrolled = useScrollHandler();
  const location = useLocation();

  const isPermanentOrLocumJobsRoute = (pathname: string) => {
    return (
      (pathname.startsWith('/permanent-') || pathname.startsWith('/locum-')) &&
      pathname.includes('-jobs-in-')
    );
  };

  const matchStateRegionRoute = () => {
    const routes = [
      '/all-jobs-in-australia',
      '/permanent-jobs/:location/:profession-jobs',
      '/locum-jobs/:location/:profession-jobs',
      // '/permanent-jobs/:stateName',
      // '/locum-jobs/:stateName',
      '/specialty/:specialtyUrl',
      '/region/permanent-jobs/:regionName',
      '/region/locum-jobs/:regionName',
      '/:stateName/:regionName/jobs',
      '/all-doctor-jobs-in-australia',
      '/all-ahp-jobs-in-australia',
      // '/all-permanent-doctor-jobs-in-australia',
      // '/all-locum-doctor-jobs-in-australia',
      // '/all-permanent-nurses-jobs-in-australia',
      // '/all-permanent-psychology-jobs-in-australia',
      // '/all-locum-nurses-jobs-in-australia',
      // '/all-locum-psychology-jobs-in-australia',
      // '/all-permanent-occupational-therapists-jobs-in-australia',
      // '/all-locum-occupational-therapists-jobs-in-australia',
      // '/all-locum-nurses-jobs-in-australia',
      // '/all-permanent-dentists-jobs-in-australia',
      // '/all-locum-dentists-jobs-in-australia',
      '/specialty/permanent/:specialty-jobs-in-australia',
      '/specialty/locum/:specialty-jobs-in-australia',
      '/seniority/permanent/:seniority-jobs-in-australia',
      '/seniority/locum/:seniority-jobs-in-australia,',
      '/city/all-jobs/:cityName',
    ];

    return routes.some((route) => matchPath(route, location.pathname));
  };

  const getBackgroundColor = () => {
    const pathname = location.pathname;

    const isJobsRoute =
      isPermanentOrLocumJobsRoute(pathname) || matchStateRegionRoute();

    return isJobsRoute ? (scrolled ? 'bg-blue-500' : 'bg-transparent') : '';
  };

  return (
    <section className="fixed top-0 w-full flex justify-center z-10 transition-colors duration-300">
      <nav
        className={`max-width-container ${getBackgroundColor()} transition-colors duration-500`}
      >
        <div className="main-width-container">
          <TopNavbar
            stateWithRegions={stateWithRegions}
            fetchStatesWithRegions={fetchStatesWithRegions}
          />
          <BottomNavbar bottomMenuData={bottomMenuData} />
        </div>
      </nav>
    </section>
  );
}
