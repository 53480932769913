import { getBaseUrl } from '@/config/BaseUrl';

interface FilterJobsParameterProps {
  divisionId?: number | null;
  professionId?: number | null;
  stateId?: number | null;
  seniorityId?: number | null;
  specialtyId?: number | null;
  suburbId?: number | null;
  regionId?: number | null;
  perPage?: number;
  page?: number;
}

export default async function GetAllJobDetailsApi({
  divisionId = null,
  professionId = null,
  stateId = null,
  specialtyId = null,
  seniorityId = null,
  regionId = null,
  suburbId = null,
}: FilterJobsParameterProps) {
  console.log(divisionId);
  console.log(professionId);
  console.log(stateId);
  console.log(specialtyId);
  console.log(seniorityId);
  console.log(regionId);
  console.log(suburbId);

  let res: string = '';
  try {
    const baseUrl = getBaseUrl();

    let url = `${baseUrl}web/jobdetails/filter?`;
    if (
      professionId ||
      stateId ||
      seniorityId ||
      specialtyId ||
      divisionId ||
      suburbId ||
      regionId
    ) {
      url += '';
      if (divisionId !== null) url += `division=${divisionId}&`;
      if (professionId !== null) url += `profession=${professionId}&`;
      if (stateId !== null) url += `state=${stateId}&`;
      if (specialtyId !== null) url += `speciality=${specialtyId}&`;
      if (seniorityId !== null) url += `seniority=${seniorityId}&`;
      if (regionId !== null) url += `region=${regionId}&`;
      if (suburbId !== null) url += `suburb=${suburbId}&`;
      if (url.endsWith('&')) url = url.slice(0, -1);
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
    };

    const response = await fetch(url, requestOptions);

    res = await response.json();
  } catch (error) {
    console.error('Error getting all Jobs: ', error);
    res = error;
  }
  return res;
}
