import { ReactNode, forwardRef } from 'react';

interface PageLayoutProps {
  children: ReactNode;
  bgColor?: string; // Optional background color
}

const PageLayout = forwardRef<HTMLDivElement, PageLayoutProps>(
  ({ children, bgColor }, ref) => {
    return (
      <section
        ref={ref}
        className={`max-width-container ${bgColor ? `bg-${bgColor}` : ''}`}
      >
        <div className="main-width-container">{children}</div>
      </section>
    );
  }
);

export default PageLayout;
