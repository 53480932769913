import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const SocialMediaWidget = () => {
  const [activeItem, setActiveItem] = useState<string | null>(null);

  const handleClick = (item: string) => {
    setActiveItem(activeItem === item ? null : item);
  };

  return (
    <div className="fixed right-0 top-1/2 transform -translate-y-1/2 z-[1000]">
      <ul className="flex flex-col items-end">
        {/* Hotline Section */}
        <li className="transform transition-all duration-300">
          <div
            onClick={() => handleClick('hotline')}
            className={`flex items-center justify-start bg-[#146F99] text-white overflow-hidden cursor-pointer transition-all duration-300 
              ${activeItem === 'hotline' ? 'w-[220px] h-[75px]' : 'w-[75px] h-[75px]'}`}
          >
            {activeItem !== 'hotline' && (
              <div className="flex flex-col justify-center items-center w-full">
                <img
                  src="https://medfuturebucket.s3.ap-southeast-1.amazonaws.com/desktop/website/icons/social_hotline.png"
                  alt="Hotline"
                  className="w-4 h-4 object-cover object-center"
                />
                <span className="text-[11px] font-sans font-normal leading-3 mt-2">
                  Hotline
                </span>
              </div>
            )}
            {activeItem === 'hotline' && (
              <div className="flex items-center gap-x-2 px-4">
                <img
                  src="https://medfuturebucket.s3.ap-southeast-1.amazonaws.com/desktop/website/icons/social_hotline.png"
                  alt="Hotline"
                  className="w-4 h-4 object-cover object-center"
                />
                <span className="font-sans font-bold text-base">
                  Hotline: <span className="font-normal">1300 633 388</span>
                </span>
              </div>
            )}
          </div>
        </li>

        {/* Email Section - Redirects */}
        <li className="transform transition-all duration-300">
          <Link
            to={`mailto:medfuture@themedfuture.com`}
            className="flex items-center justify-center bg-[#198FC7] text-white w-[75px] h-[75px] cursor-pointer"
            onClick={() => handleClick('email')}
          >
            <div className="flex flex-col justify-center items-center">
              <img
                src="https://medfuturebucket.s3.ap-southeast-1.amazonaws.com/desktop/website/icons/social_email.png"
                alt="Email"
                className="w-5 h-4 object-cover object-center"
              />
              <span className="text-[11px] font-sans font-normal leading-3 mt-2">
                Email
              </span>
            </div>
          </Link>
        </li>

        {/* WhatsApp Section - Redirects */}
        <li className="transform transition-all duration-300">
          <Link
            to="https://wa.me/+61452668811"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center bg-[#2FAAE4] text-white w-[75px] h-[75px] cursor-pointer"
            onClick={() => handleClick('whatsapp')}
          >
            <div className="flex flex-col justify-center items-center">
              <img
                src="https://medfuturebucket.s3.ap-southeast-1.amazonaws.com/desktop/website/icons/social_whatsapp.png"
                alt="Whatsapp"
                className="w-5 h-5 object-cover object-center"
              />
              <span className="text-[11px] font-sans font-normal leading-3 mt-2">
                Whatsapp
              </span>
            </div>
          </Link>
        </li>

        {/* International Section - Redirects */}
        <li className="transform transition-all duration-300">
          <Link
            to="https://wa.me/+61452668811"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center bg-[#5CBDEA] text-white w-[75px] h-[75px] cursor-pointer"
            onClick={() => handleClick('international')}
          >
            <div className="flex flex-col justify-center items-center">
              <img
                src="https://medfuturebucket.s3.ap-southeast-1.amazonaws.com/desktop/website/icons/social_global.png"
                alt="International"
                className="w-5 h-5 object-cover object-center"
              />
              <span className="text-[11px] font-sans font-normal leading-3 mt-2">
                International
              </span>
            </div>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default SocialMediaWidget;
