import { useState } from 'react';
import Slider, { Settings } from 'react-slick';
import MobileJobCard from '../../../cards/mobileJobCard/MobileJobCard';

export default function MobileOffer({ data }) {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  // const filteredJobData = data?.filter((job) => job.is_permanent === '1');
  const settings: Settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    rows: 3,
    slidesToScroll: 1,
    slidesPerRow: 1,
    autoplay: false,
    touchMove: true,
    className: 'custom-setting',
    appendDots: (dots: JSX.Element[]) => (
      <ul className="slick-dots">
        {dots.slice(0, 3).map((dot, index) => (
          <li key={index} className="inline-block m-2 cursor-pointer">
            {dot}
          </li>
        ))}
      </ul>
    ),
    customPaging: (i: number) => (
      <div
        className={`w-[0.688rem] h-[0.688rem] lg:w-[0.813rem] lg:h-[0.813rem] rounded-full ${i === activeIndex ? 'bg-blue-400' : 'bg-gray-450'}`}
      />
    ),
    beforeChange: () => {
      const maxIndex = 2;
      setActiveIndex((oldIndex) => {
        const newIndex = oldIndex + 1;
        return newIndex > maxIndex ? 0 : newIndex;
      });
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // slidesPerRow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.15,
          slidesToScroll: 1,
          // slidesPerRow: 1,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          // slidesPerRow: 1,
        },
      },
    ],
  };
  return (
    <section className="mobile-padding-container">
      <div className="flex items-center justify-between mb-5">
        <h2 className="section_header_text w-[80%]">
          Jobs With Exceptional Offer
        </h2>
      </div>
      <div>
        <div>
          <Slider {...settings}>
            {data?.map((offer) => (
              <div key={offer.jobdetails_id} className="p-[2px] md:p-2">
                <MobileJobCard offer={offer} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
}
