import React, { useEffect } from 'react';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const [visiblePages, setVisiblePages] = React.useState<number[]>([]);

  useEffect(() => {
    const updateVisiblePages = () => {
      const pages: number[] = [];
      const range = 5;
      let start = Math.max(1, currentPage - Math.floor(range / 2));
      const end = Math.min(totalPages, start + range - 1);

      if (end - start + 1 < range) {
        start = Math.max(1, end - range + 1);
      }

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      setVisiblePages(pages);
    };

    updateVisiblePages();
  }, [currentPage, totalPages]);

  return (
    <div className="flex justify-center space-x-3">
      {currentPage > 1 && (
        <button
          onClick={() => onPageChange(currentPage - 1)}
          className="text-blue-500 px-3 py-1 rounded"
        >
          Previous
        </button>
      )}

      {visiblePages.map((number) => (
        <button
          key={number}
          onClick={() => onPageChange(number)}
          className={`px-3 py-1 rounded ${
            number === currentPage
              ? 'bg-blue-10 text-blue-400'
              : 'bg-gray-320 text-blue-330'
          }`}
        >
          {number}
        </button>
      ))}

      {currentPage < totalPages && (
        <button
          onClick={() => onPageChange(currentPage + 1)}
          className="text-gray-335 px-3 py-1 rounded"
        >
          Next
        </button>
      )}
    </div>
  );
};

export default Pagination;
