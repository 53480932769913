import React from 'react';
import { motion } from 'framer-motion';
import PlusIcon from '/icons/FQPlusIcon.png';
import CrossIcon from '/icons/FQMinusIcon.png';

interface DropDownItemProps {
  question: string;
  answer: string;
  id: number;
  isOpen: boolean;
  onToggle: () => void;
}

const DropDownItem: React.FC<DropDownItemProps> = ({
  question,
  answer,
  isOpen,
  onToggle,
}) => {
  const textVariants = {
    hidden: { opacity: 0.8, y: -10 },
    visible: { opacity: 1, y: 0, transition: { duration: 1.5 } },
  };

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    e.currentTarget.style.setProperty('--mouse-x', `${x}px`);
    e.currentTarget.style.setProperty('--mouse-y', `${y}px`);
  };

  return (
    <div className="bg-white rounded-xl shadow-xl p-5">
      <motion.div
        className="flex items-center justify-between gap-x-5 bg-white w-full p-4 mb-2 mt-5 cursor-pointer relative rounded-[30px]"
        onClick={onToggle}
        onMouseMove={handleMouseMove}
        whileHover={{
          scale: 1.01,
          transition: { duration: 1.5 },
        }}
        style={{
          '--mouse-x': '50%',
          '--mouse-y': '50%',
        }}
      >
        <h2
          className={`font-sans lg:text-[20px] 2xl:text-[22px] leading-[30px] font-normal ${isOpen ? 'text-blue-390' : 'text-blue-390 '}`}
        >
          {question}
        </h2>
        <span>
          {isOpen ? (
            <div className="w-[24px] h-[24px] sm:w-[28px] sm:h-[28px] lg:w-[32px] lg:h-[32px] flex items-center justify-center bg-blue-400 rounded-md">
              <img
                width={18}
                height={18}
                src={CrossIcon}
                alt="Close Icon"
                className="object-contain object-center cursor-pointer"
              />
            </div>
          ) : (
            <div className="w-[24px] h-[24px] sm:w-[28px] sm:h-[28px] lg:w-[32px] lg:h-[32px] flex items-center justify-center bg-blue-50 rounded-md">
              <img
                width={18}
                height={18}
                src={PlusIcon}
                alt="Open Icon"
                className="object-contain object-center cursor-pointer"
              />
            </div>
          )}
        </span>
        <style jsx>{`
          div::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: radial-gradient(
              circle at var(--mouse-x) var(--mouse-y),
              var(--hover-color),
              transparent 50%
            );
            opacity: 0.3;
            pointer-events: none;
            transition: background 0.5s;
          }
        `}</style>
      </motion.div>

      {isOpen && (
        <motion.div
          className="px-8 font-sans font-normal lg:text-base lg:leading-[28px] 2xl:text-[17px] 2xl:leading-[30px] text-blue-700"
          initial="hidden"
          animate="visible"
          variants={textVariants}
        >
          {answer}
        </motion.div>
      )}
    </div>
  );
};

export default DropDownItem;
