import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useFooterBackgroundColor = () => {
  const location = useLocation();
  const [footerBackgroundColor, setFooterBackgroundColor] =
    useState('bg-blue-500');

  useEffect(() => {
    const getFooterBackgroundColor = (path: string) => {
      if (
        path === '/premier-locum-general-practitioner' ||
        path === '/premier-locum-doctor'
      ) {
        return 'bg-gradient-premierFooter';
      } else if (path === '/allied-health-professional') {
        return 'bg-gradient-spotFooter';
      } else if (path === '/nursing') {
        return 'bg-gradient-nurse';
      } else {
        return 'bg-blue-500';
      }
    };

    const path = location.pathname;
    const color = getFooterBackgroundColor(path);
    setFooterBackgroundColor(color);
  }, [location.pathname]);

  return footerBackgroundColor;
};

export default useFooterBackgroundColor;
