import { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { slickSettings } from '@/lib/Caresoul/CaresoulMainSettings';
import LocumJobCard from '../../cards/jobCard/JobCard';
import SubmitFormModal from '@/components/models/SubmitFormModal';
import { motion } from 'framer-motion';
import ShareModal from '@/components/models/ShareModal';

export default function Locum({ data }) {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const settings = slickSettings({ activeIndex, setActiveIndex });
  // const { allJobData } = useStateContext();
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  const [jobUrl, setJobUrl] = useState<string>('');

  // const filteredJobData = data?.filter((job) => job.is_permanent !== '1');

  const handleShareClick = (url: string) => {
    setJobUrl(url);
    setIsShareModalOpen(true);
  };

  const handleCloseShareModal = () => {
    setIsShareModalOpen(false);
  };

  const Variant = {
    initial: {
      scale: 0.8,
      opacity: 0.5,
    },
    animate: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 1,
        staggerChildren: 0.2,
      },
    },
  };

  const textVariant = {
    initial: {
      x: -80,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 1.5,
        staggerChildren: 0.2,
      },
    },
  };
  return (
    <motion.section className="padding-container">
      <motion.div
        variants={textVariant}
        initial="initial"
        whileInView="animate"
      >
        <motion.h2 className="section_header_text" variants={textVariant}>
          Locum Jobs
        </motion.h2>
      </motion.div>
      <motion.div
        className="my-5"
        variants={Variant}
        initial="initial"
        whileInView="animate"
      >
        <Slider {...settings}>
          {data?.slice(0, 12)?.map((offer) => (
            <div key={offer.jobdetails_id} className="my-1">
              <LocumJobCard
                key={offer.jobdetails_id}
                offer={offer}
                onShareClick={handleShareClick}
              />
            </div>
          ))}
        </Slider>
      </motion.div>
      <div className={`modal ${isShareModalOpen ? 'modal-open' : ''}`}>
        <ShareModal
          isOpen={isShareModalOpen}
          onClose={handleCloseShareModal}
          jobUrl={jobUrl}
        />
      </div>
    </motion.section>
  );
}
