import { ButtonProps } from '@/types/types';
import { motion } from 'framer-motion';

export default function Button({
  type,
  title,
  variant,
  handleClick,
  iconSrc,
}: ButtonProps) {
  const iconHoverVariant = {
    hover: {
      rotate: 360,
    },
  };

  return (
    <motion.button
      className={`relative border-none rounded-md cursor-pointer flex items-center justify-center ${variant}`}
      type={type}
      onClick={handleClick}
      whileHover="hover"
    >
      <>
        <span className="text-nowrap text-sm lg:text-base">{title}</span>
        {iconSrc && (
          <motion.img
            src={iconSrc}
            alt="Icon"
            className="ml-2 w-4 h-4"
            variants={iconHoverVariant}
          />
        )}
      </>
    </motion.button>
  );
}
