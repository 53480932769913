import PageLoadingComponent from '@/components/noData/PageLoadingComponent';
import { Home } from '@/views';
import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const MainAllJob = lazy(() => import('@/views/allJobs/MainAllJob'));
const PermanentJob = lazy(() => import('@/views/permanent/PermanentJob'));
const LocumJob = lazy(() => import('@/views/locum/LocumJob'));
const LocationLanding = lazy(
  () => import('@/views/locationLanding/LocationLanding')
);
const ProfessionalLanding = lazy(
  () => import('@/views/ProfessionalLanding/ProfessionalLanding')
);

const AllJobsBySeniority = lazy(
  () => import('@/views/dynamic/AllJobsBySeniority')
);
const AllJobsByLocation = lazy(
  () => import('@/views/dynamic/AllJobsBySateWithRegions')
);
const AllJobsByPermanentOrLocum = lazy(
  () => import('@/views/dynamic/AllJobsByPermanentOrLocum')
);

const AllJobsBySpecialty = lazy(
  () => import('@/views/dynamic/AllJobsBySpecialty')
);

const AllJobsByState = lazy(() => import('@/views/dynamic/AllJobsByState'));
const MegaFilterPage = lazy(() => import('@/views/allJobs/MegaFilterPage'));
const JobBoardFilter = lazy(() => import('@/views/allJobs/JobBoardFilterMain'));
const FilterCityMain = lazy(() => import('@/views/allJobs/FilterCityMain'));
const CandidateSignIn = lazy(
  () => import('@/views/signInPages/CandidateSignIn')
);
const ClientSignIn = lazy(() => import('@/views/signInPages/ClientSignIn'));
const WrappedPremierLocumGP = lazy(
  () => import('@/views/cardPages/PremierLocumGP')
);
const PageNotFound = lazy(() => import('@/views/Errors/PageNotFound'));
const NotImplemented = lazy(() => import('@/views/Errors/NotImaplementted'));
const ApiServerError = lazy(() => import('@/views/Errors/ServerError'));
const TooManyRequest = lazy(() => import('@/views/Errors/TooManyRequest'));
const SeniorityLandingPage = lazy(
  () => import('@/views/landingPages/SeniorityLandingPage')
);
const AllMedicalJobs = lazy(() => import('@/views/dynamic/AllMedicalJobs'));
const CForceNurse = lazy(() => import('@/views/cardPages/CForceNurse'));
const Maintenance = lazy(() => import('@/views/Errors/Maintenance'));
const TestimonialPage = lazy(() => import('@/views/other/TestimonialPage'));
const CandidateConsultation = lazy(
  () => import('@/views/consulation/CandidateConsulation')
);
const ClientConsultation = lazy(
  () => import('@/views/consulation/ClientConsulation')
);
const ThirtyMinutesConsultation = lazy(
  () => import('@/views/consulation/ThirtyMinutesConsultation')
);
const OneHourConsultation = lazy(
  () => import('@/views/consulation/OneHourConsultation')
);
const CareerConsultation = lazy(
  () => import('@/views/consulation/CarrerConsultation')
);
const EmployerShortConsultation = lazy(
  () => import('@/views/consulation/EmployerShortConsultation')
);
const EmployerFullConsultation = lazy(
  () => import('@/views/consulation/EmployerFullConsultation')
);
const EmployerCareerConsultation = lazy(
  () => import('@/views/consulation/EmployerCarrerConsultation')
);
const Register = lazy(() => import('@/views/register/CandidateRegistration'));
const EmployerRegistration = lazy(
  () => import('@/views/register/EmployerRegistration')
);
const EmployerService = lazy(
  () => import('@/views/employerSevices/EmployerService')
);
const Compliance = lazy(() => import('@/views/policyPages/Compliance'));
const PrivacyPolicy = lazy(() => import('@/views/policyPages/PrivacyPolicy'));
const TermsAndCondition = lazy(
  () => import('@/views/policyPages/Terms&Condition')
);
const ReferAndEarn = lazy(() => import('@/views/other/ReferAndEarn'));
const SpotWorkForce = lazy(() => import('@/views/cardPages/SpotWorkForce'));
const PremierLocumDoctor = lazy(
  () => import('@/views/cardPages/PremierLocumDoctor')
);
const PremierLocumGP = lazy(() => import('@/views/cardPages/PremierLocumGP'));
const PermanentRecruitment = lazy(
  () => import('@/views/employerSevices/PermanentRecruitment')
);
const TemporaryRecruitment = lazy(
  () => import('@/views/employerSevices/TemporaryRecruitment')
);
const InternationalRecruitment = lazy(
  () => import('@/views/employerSevices/InternationalRecruitment')
);

const ContactUs = lazy(() => import('@/views/contact/ContactUs'));
const InternationalMedicalGraduate = lazy(
  () =>
    import(
      '@/views/internationalRecruitment/InternationalMedicalGraduatePlacement'
    )
);

export default function AppRouter({ bottomMenuData }) {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="*"
          element={
            <Suspense fallback={<PageLoadingComponent />}>
              <Routes>
                <Route path="/register" element={<Register />} />
                <Route
                  path="/employer-register"
                  element={<EmployerRegistration />}
                />
                <Route
                  path="/premier-locum-general-practitioner"
                  element={<PremierLocumGP />}
                />
                <Route
                  path="/allied-health-professional"
                  element={<SpotWorkForce />}
                />
                <Route
                  path="/premier-locum-doctor"
                  element={<PremierLocumDoctor />}
                />
                <Route path="/nursing" element={<CForceNurse />} />
                <Route path="/sign-in" element={<CandidateSignIn />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/client-sign-in" element={<ClientSignIn />} />
                <Route
                  path="/terms-and-conditions"
                  element={<TermsAndCondition />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/compliance" element={<Compliance />} />
                <Route path="/employer-service" element={<EmployerService />} />
                <Route
                  path="/customer-feedback"
                  element={<TestimonialPage />}
                />
                <Route
                  path="/candidate-consultation"
                  element={<CandidateConsultation />}
                />
                <Route
                  path="/employer-consultation"
                  element={<ClientConsultation />}
                />
                <Route
                  path="/candidate-short-consultation"
                  element={<ThirtyMinutesConsultation />}
                />
                <Route
                  path="/candidate-full-consultation"
                  element={<OneHourConsultation />}
                />
                <Route
                  path="/candidate-career-consultation"
                  element={<CareerConsultation />}
                />
                <Route
                  path="/employer-short-consultation"
                  element={<EmployerShortConsultation />}
                />
                <Route
                  path="/employer-full-consultation"
                  element={<EmployerFullConsultation />}
                />
                <Route
                  path="/employer-90-minutes-consultation"
                  element={<EmployerCareerConsultation />}
                />
                <Route
                  path="/permanent-recruitment"
                  element={<PermanentRecruitment />}
                />
                <Route
                  path="/temporary-recruitment"
                  element={<TemporaryRecruitment />}
                />
                <Route
                  path="/international-recruitment"
                  element={<InternationalRecruitment />}
                />
                <Route path="/refer-and-earn" element={<ReferAndEarn />} />
                <Route
                  path="/all-jobs-in-australia"
                  element={<MegaFilterPage />}
                />
                {/* Specific profession job in location route */}
                <Route
                  path="/profession/:professionUrl"
                  element={<ProfessionalLanding />}
                />

                {/* Generic jobs in location route */}
                <Route
                  path="/state/:locationUrl"
                  element={<LocationLanding />}
                />
                <Route
                  path="/seniority/:seniorityUrl"
                  element={
                    <SeniorityLandingPage bottomMenuData={bottomMenuData} />
                  }
                />

                {/* Specific professional job in Australia route */}
                <Route
                  path="/permanent-jobs/:location/:profession-jobs"
                  element={<MainAllJob />}
                />
                <Route
                  path="/locum-jobs/:location/:profession-jobs"
                  element={<MainAllJob />}
                />

                {/* Other Dynamic routes*/}
                <Route
                  path="/apply-now/permanent/:title/:jobId"
                  element={<PermanentJob />}
                />
                <Route
                  path="/apply-now/locum/:title/:jobId"
                  element={<LocumJob />}
                />
                {/* General job listings */}
                <Route
                  path="/permanent-jobs/:stateName"
                  element={<AllJobsByState />}
                />
                <Route
                  path="/locum-jobs/:stateName"
                  element={<AllJobsByState />}
                />
                <Route
                  path="/region/permanent-jobs/:regionName"
                  element={<AllJobsByLocation />}
                />
                <Route
                  path="/region/locum-jobs/:regionName"
                  element={<AllJobsByLocation />}
                />
                <Route
                  path="/city/all-jobs/:cityName"
                  element={<FilterCityMain />}
                />
                {/* route path for hard code jobs in mega menu */}
                <Route
                  path="/all-doctor-jobs-in-australia"
                  element={<JobBoardFilter bottomMenuData={bottomMenuData} />}
                />
                <Route
                  path="/all-ahp-jobs-in-australia"
                  element={<JobBoardFilter bottomMenuData={bottomMenuData} />}
                />
                <Route
                  path="/all-permanent-doctor-jobs-in-australia"
                  element={<AllMedicalJobs bottomMenuData={bottomMenuData} />}
                />
                <Route
                  path="/all-locum-doctor-jobs-in-australia"
                  element={<AllMedicalJobs bottomMenuData={bottomMenuData} />}
                />
                <Route
                  path="/all-permanent-occupational-therapists-jobs-in-australia"
                  element={<AllJobsByPermanentOrLocum />}
                />
                <Route
                  path="/all-locum-occupational-therapists-jobs-in-australia"
                  element={<AllJobsByPermanentOrLocum />}
                />
                <Route
                  path="/all-permanent-speech-pathologist-jobs-in-australia"
                  element={<AllJobsByPermanentOrLocum />}
                />
                <Route
                  path="/all-locum-speech-pathologist-jobs-in-australia"
                  element={<AllJobsByPermanentOrLocum />}
                />
                <Route
                  path="/all-permanent-speech-therapist-jobs-in-australia"
                  element={<AllJobsByPermanentOrLocum />}
                />
                <Route
                  path="/all-locum-speech-therapist-jobs-in-australia"
                  element={<AllJobsByPermanentOrLocum />}
                />
                <Route
                  path="/all-permanent-physiotherapist-jobs-in-australia"
                  element={<AllJobsByPermanentOrLocum />}
                />
                <Route
                  path="/all-locum-physiotherapist-jobs-in-australia"
                  element={<AllJobsByPermanentOrLocum />}
                />
                <Route
                  path="/all-permanent-dentists-jobs-in-australia"
                  element={<AllJobsByPermanentOrLocum />}
                />
                <Route
                  path="/all-locum-dentists-jobs-in-australia"
                  element={
                    <AllJobsByPermanentOrLocum
                      bottomMenuData={bottomMenuData}
                    />
                  }
                />
                <Route
                  path="/all-permanent-nurses-jobs-in-australia"
                  element={<AllMedicalJobs bottomMenuData={bottomMenuData} />}
                />
                <Route
                  path="/all-locum-nurses-jobs-in-australia"
                  element={<AllMedicalJobs bottomMenuData={bottomMenuData} />}
                />
                <Route
                  path="/all-permanent-psychology-jobs-in-australia"
                  element={<AllJobsByPermanentOrLocum />}
                />
                <Route
                  path="/all-locum-psychology-jobs-in-australia"
                  element={<AllJobsByPermanentOrLocum />}
                />
                <Route
                  path="/all-permanent-:profession-jobs-in-australia"
                  element={<AllJobsByPermanentOrLocum />}
                />
                <Route
                  path="/specialty/permanent/:specialty-jobs-in-australia"
                  element={<AllJobsBySpecialty />}
                />
                <Route
                  path="/specialty/locum/:specialty-jobs-in-australia"
                  element={<AllJobsBySpecialty />}
                />
                <Route
                  path="/seniority/permanent/:seniority-jobs-in-australia"
                  element={<AllJobsBySeniority />}
                />
                <Route
                  path="/seniority/locum/:seniority-jobs-in-australia"
                  element={<AllJobsBySeniority />}
                />
                <Route
                  path="/international-medical-graduate-recruitment"
                  element={<InternationalMedicalGraduate />}
                />
                <Route path="*" element={<Navigate to="/page-not-found" />} />
                <Route path="/page-not-found" element={<PageNotFound />} />
                <Route path="/server-error" element={<ApiServerError />} />
                <Route path="/not-implemented" element={<NotImplemented />} />
                <Route path="/many-requests" element={<TooManyRequest />} />
                <Route path="/maintenance" element={<Maintenance />} />
              </Routes>
            </Suspense>
          }
        />
      </Routes>
    </>
  );
}
